<template>
  <div class="wrapper w">
    <div v-if="componentInfo.config.showTitle" class="title">
      <div class="title_name">
        <index-title-icon />
        <span>{{ componentInfo.config.title }}</span>
      </div>
      <NuxtLink to="/book/list">
        <div v-if="componentInfo.config.showMore" class="title_more flex_align">更多<i class="arrows" /></div>
      </NuxtLink>
    </div>
    <div class="course_item_wrapper clearfix">
      <template v-if="componentInfo.config.showType !== 1">
        <custom-link :to="handleNavigate(componentInfo.config)" :target-type="componentInfo.config.targetType"
          :target-open-new-page="componentInfo.config.targetOpenNewPage">
          <div class="course_item_ad course_item fl"
            :style="{ height: componentInfo.config.showType === 2 ? '219px' : '453px' }">
            <div class="course_item_content">
              <div v-if="componentInfo.config.imageUrl" class="item_image_wrapper">
                <img class="item_image" :src="componentInfo.config.imageUrl">
              </div>
            </div>
          </div>
        </custom-link>
      </template>
      <!--      <div class="book_list">-->
      <NuxtLink :to="'/book/view?id=' + item.id || item.goodsId" v-for="(item, index) in componentInfo.data"
        :key="index" class="item_wrapper">
        <div class="data_item">
          <div v-if="item.logoUrl" class="item_image_wrapper">
            <img class="item_image" :src="item.logoUrl">
          </div>
          <div v-else class="default_image_wrapper"></div>
          <div class="info">
            <div class="name tx-ov" v-html="item.name || item.goodsName"></div>
            <div class="c_999 mgt5 tx-ov">作者：{{ item.author }}</div>
            <div v-if="!item.publishComingSoon" class="info_desc mgt10">
              <div class="price">
                <span :class="['current_price', item.marketingPrice == 0 ? 'free' : '']">
                  {{ item.marketingPrice || '免费' }}</span>
                <span v-if="item.scribingPrice" class="old_price">{{ item.scribingPrice }}</span>
              </div>
              <div class="learn_num"><span>{{ item.studyNum + (item.virtualUser || 0) + ' 人在读' }}</span></div>
            </div>
            <div v-if="item.publishComingSoon" class="info_desc mgt10">
              <div class="price">
                <span class="current_price">即将上架</span>
              </div>
            </div>
          </div>
          <div class="publish_coming_soon_tag" v-if="item.publishComingSoon">即将上架</div>
        </div>
      </NuxtLink>

      <el-empty v-if="!componentInfo.data || componentInfo.data.length == 0" description="暂无内容" />
      <!--      </div>-->
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
const props = defineProps({
  componentInfo: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { shopData } = useUserStore()
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 25px 0px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  line-height: 22px;
  color: #333;
}

.title_name {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;

  img {
    width: 24px;
    height: 24px;
  }
}

.main {
  display: flex;
  flex-wrap: wrap;
}

.item_wrapper {
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  width: 224px;
  display: inline-block;

  &:nth-child(5n) {
    margin-right: 0;
  }
}

.item_wrapper:nth-last-child(-n + 5) {
  margin-bottom: 0;
}

.data_item {
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: all 0.3s;
  }

  .publish_coming_soon_tag {
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 0px 8px 0px 8px;
    right: 0;
    top: 0;
    padding: 8px 12px;
    background: #497fed;
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    z-index: 10;
    color: #ffffff;
  }
}

.course_item_wrapper {
  margin-right: -10px;
  margin-left: -10px;

  .course_item_ad {
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;

    .item_image_wrapper {
      height: 100%;
    }
  }
}

.course_item_content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: all 0.3s;
  }
}

.item_image_wrapper {
  height: 320px;
  width: 224px;

  .item_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.default_image_wrapper {
  height: 128px;
  width: 230px;
  overflow: hidden;
  background: #3d7fff12;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  .default_image {
    width: 26px;
    height: 26px;
  }
}

.info {
  flex: 1;
  padding: 10px 10px 15px;
}

.name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.info_desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  color: #999999;
  line-height: 18px;

  .border {
    width: 1px;
    padding: 0.5px;
    margin: 0px 10px;
    height: 12px;
    background: #ebeef5;
  }
}

.price {
  line-height: 18px;

  .current_price {
    font-size: 16px;
    font-weight: 550;
    color: #F61818;

    &.free {
      color: #58B85C;
    }
  }

  .current_price::before {
    content: '￥';
    font-size: 0.66em;
  }

  .free::before {
    display: none;
  }

  .old_price {
    margin-left: 5px;
    font-size: 12px;
    line-height: 18px;
    color: #999999;
    text-decoration-line: line-through;
  }

  .old_price::before {
    content: '￥';
    font-size: 1em;
  }
}
</style>
